export const apiPaths = {
  // GENERAL
  profile: '/profile',
  users: '/users',
  departments: '/departments',
  field_of_study: '/field_of_studies',
  field_of_study_bu_faculty: '/field_of_studies/search_from_all_users',

  notification_request_list: '/requests/notifications_count',
  notification_request_menu: '/requests/total_notification_count',
  notifications_inprogress_count: '/requests/notifications_inprogress_count',

  // MASTER
  document_statuses: '/document_statuses',
  academic_positions: '/academic_positions',
  academic_years: '/academic_years',
  semesters: '/semesters',
  education_levels: '/education_levels',
  eval_documents: '/eval_documents',
  committee_types: '/committee_types',
  committee_priorities: '/committee_priorities',
  time_slot: '/time_zlots',
  skills_: '/skills',
  skills_from_all_user: '/skills/search_from_all_users',
  request_owners: '/requests/request_owners',
  request_chairmans: '/requests/request_chairmans',
  requesters: '/requests/requesters',
  requester_field_of_study: '/requests/requester_field_of_studies',

  //
  // SETTINGS
  faculties: '/faculties',

  //
  // VERIFY DOC_NO
  request_verify_doc_no: '/requests/verify_doc_no',
  invitation_verify_doc_no: '/invitation_letters/verify_doc_no',
  //
  // DOCUMENTS
  requests: '/requests',
  request_tracking: '/requests/tracking',
  count_meeting_request: '/requests/count_meeting_requests',

  invitation_letters: '/invitation_letters',
  committee_results: '/committee_results',
  request_results: '/request_results',
  committee_result_eval_documents: '/committee_result_eval_documents',
  committees: '/committees',
  // STATUS
  verify: '/verify',
  choose_committee: '/choose_committee',
  wait_committee: '/wait_committee',
  wait_appointment: '/wait_appointment',
  evaluating: '/evaluating',
  wait_result: '/wait_result',
  wait_revise: '/wait_revise',
  verify_revised: '/verify_revised',
  head_verify_revised: '/head_verify_revised',
  create_cert: '/create_cert',
  approve_cert: '/approve_cert',
  approved: '/approved',
  reject: '/reject',
  renew_expiration_date: '/renew_expiration_date',

  // CALENDAR
  // calendar: '/calendar_time_zlots',
  calendar: '/calendar_time_zlots/group_time_zlot',
  calendar_all: '/calendar_time_zlots/create_all',

  // CALENDAR
  appointment_docs: '/appointment_docs',

  // REPORT
  requester_report: '/report/requests',
  committee_count_report: '/report/count_committees',
  committee_report: '/report/committees',
  committee_summary_count_report: '/report/count_summary_committees',
  committee_summary_report: '/report/summary_committees',
};
