export const locale = {
  lang: 'th',
  data: {
    LOGIN: {
      WELCOME_TEXT: 'ยินดีต้อนรับสู่',
      WELCOME_DESC: 'ลงชื่อเข้าใช้บัญชีของคุณและเริ่มใช้งานระบบ',
      USERNAME: 'ชื่อผู้ใช้งาน',
      EMAIL: 'อีเมล',
      PASSWORD: 'รหัสผ่าน',
      SIGN_IN: 'เข้าสู่ระบบ',
      SIGN_OUT: 'ออกจากระบบ',
      INVALID_LOGIN: 'ชื่อผู้ใช้งานหรือรหัสผ่านที่คุณป้อนไม่ถูกต้อง โปรดตรวจสอบและลองอีกครั้ง',
      INVALID_LOGIN_SSO: 'ไม่พบผู้ใช้งานหรือ KKU Mail ไม่ถูกต้อง โปรดตรวจสอบและลองอีกครั้ง',
      SOCIAL_LOGIN: 'หรือ',
      SSO_SUCCESS: 'เข้าสู่ระบบผ่าน SSO เรียบร้อย',
      LOGIN_KKU: 'ลงชื่อเข้าใช้ด้วย KKU mail ที่นี่',
    },
    MENU_TITLE: {
      ASSESSMENT_REQUEST: 'รายการยื่นขอประเมิน',
      REQUESTS: 'รายการคำขอ',
      SCHEDULE_CALENDAR: 'ปฏิทินกำหนดการ',
      TRACKING: 'ติดตามสถานะเอกสาร',
      USER_MANAGEMENT: 'จัดการข้อมูลผู้ใช้',
      IMPORT_DATA: 'นำเข้าข้อมูล',
      PROFILE: 'ข้อมูลส่วนตัว',
      REVIEWER_LIST: 'รายชื่อผู้ตรวจสอบ',
      APPOINTMENT_DOC: 'รายการหนังสือแต่งตั้ง',
      APPOINTMENT_LETTER: 'หนังสือแต่งตั้ง',
      REPORTS: {
        SECTION: 'รายงาน',
        REQUESTER_REPORT: 'รายงานผู้ยื่นเสนอ',
        COMMITTEE_INDIVIDUAL_REPORT: 'รายงานกรรมการ (แบบบุคคล)',
        COMMITTEES_REPORT: 'รายงานสรุปรวมกรรมการ',
      },
    },
    APP: {
      ACCOUNT_PROFILE: 'ข้อมูลส่วนตัว',
      DRAFT: 'แบบร่าง',
      WAITING_DOC: 'เอกสารรอส่ง',
      IN_PROGRESS: 'กำลังดำเนินการ',
      HISTORY: 'ประวัติ',
      ATTACHMENTS: 'ไฟล์แนบ',
      DOC_NO: 'เลขที่',
      DATE: 'วันที่',
      DOCUMENT_NO: 'เลขที่เอกสาร',
      REQUEST_INFORMATION: 'ข้อมูลคำร้อง',
      CONCLUSION: 'พิจารณาสรุปผล',
      REVISE_FILE: 'แก้ไขเอกสาร',
      TIMELINE: 'Timeline',
      ISSUE_NO: 'เลขที่หนังสือ',
      SUBJECT: 'เรื่อง',
      DEAR: 'เรียน',
      NAME_TITLE: 'คำนำหน้าชื่อ',
      NAME: 'ชื่อ',
      FIRST_NAME: 'ชื่อ',
      LAST_NAME: 'นามสกุล',
      TYPE: 'ประเภท',
      CURRENT_POSITION: 'ตำแหน่งปัจจุบัน',
      ACADEMIC_POSITION: 'ตำแหน่ง',
      SKILL: 'ความเชี่ยวชาญ',
      MAJOR: 'สังกัดสาขาวิชา',
      MAJOR_NAME: 'สาขาวิชา',
      DEPARTMENT_MAJOR: 'ภาควิชา',
      DEPARTMENT: 'ฝ่าย',
      FACULTY: 'คณะ',
      PHONE: 'เบอร์โทรศัพท์',
      DOCUMENT_CREATER: 'ผู้สร้างเอกสาร',
      REQUESTER: 'ผู้ยื่นขอประเมิน',
      REQUESTER_INLINE: 'ผู้ยื่นขอประเมิน:',
      DATE_FORMAT: 'วัน/เดือน/ปี(พ.ศ. เช่น {{year}})',
      REQUEST_EVALUATION: 'ขอรับการประเมินผลการสอน',
      FIRST_SEMESTER: 'ภาคต้น',
      SECOND_SEMESTER: 'ภาคปลาย',
      SEMESTER_YEAR: 'ปีการศึกษา',
      YEAR: 'ปี',
      MONTH: 'เดือน',
      DAY: 'วัน',
      SUBJECT_CODE: 'รหัสวิชา',
      SUBJECT_NAME: 'รายวิชา',
      SUBJECT_NAME_PLACEHOLDER: 'ชื่อวิชาภาษาไทย (ชื่อวิชาภาษาอังกฤษ)',
      DEGREEE: 'ระดับการศึกษา',
      REQUEST_POSITION: 'ตำแหน่งที่ขอ',
      TOPIC: 'หัวข้อ',
      TAUGHT_TOPIC: 'หัวข้อที่สอน',
      PLEASE_SPECIFY: 'โปรดระบุหัวข้อ',
      EVALUATION_LECTURE_SUBJECT: 'หัวข้อประเมินบรรยาย',
      EVALUATION_PRACTICAL_SUBJECT: 'หัวข้อประเมินปฏิบัติ',
      FILE_SECTION_TXT_1:
        'หัวข้อที่เคยประเมินในระดับ ผู้ช่วยศาสตราจารย์ (ยื่นขอ รศ. ใช้ระดับ ผศ.)',
      FILE_SECTION_TXT_2: 'เอกสารประกอบการประเมินการสอน',
      FILE_SECTION_TXT_VALUE: 'เอกสารประกอบการประเมินการสอน {{value}} เอกสาร',
      FILE_NAME: 'ชื่อไฟล์',
      FILE_PLACEHOLDER: 'เลือกหรือลากและวางไฟล์ลงที่นี่',
      FILE_PLACEHOLDER_ADD_ON: 'รองรับไฟล์นามสกุล PDF',
      FILE_PLACEHOLDER_2: 'ลากและวางไฟล์ลงที่นี่หรือ',
      COPY_OF_APPLICATION_FROM_HEAD:
        'ยื่น แบบเสนอขอประเมินผลการสอนเพื่อประกอบการยื่นขอกำหนดตำแหน่งทางวิชาการ',
      COPY_OF_APPLICATION_FROM_HEAD_VIEW:
        'แบบเสนอขอประเมินผลการสอนเพื่อประกอบการยื่นขอกำหนดตำแหน่งทางวิชาการ',
      COPY_OF_APPLICATION_FROM_HEAD_WARNING:
        'กรุณาสร้าง แบบขอประเมินฯ (PDF) เพื่อยื่นหัวหน้าสาขาฯ ลงนาม digital ก่อนยืนประเมินทุกครั้ง',
      // COPY_OF_APPLICATION_FROM_HEAD: 'สำเนาแบบยื่นคำร้องที่อนุมัติจากหัวหน้าสังกัดสาขาวิชา',
      UPLOADED_FILE: 'ไฟล์ที่อัปโหลด',
      REVISION_FILE: 'แก้ไขครั้งที่',
      REMARK: 'หมายเหตุ',
      BY: 'โดย',
      TEL: 'โทร',
      PASS: 'ผ่าน',
      FAIL: 'ไม่ผ่าน',
      HAS_ADDITIONAL_EDIT: 'มีแก้ไขเพิ่มเติม',
      NO_ADDITIONAL_EDIT: 'ไม่มีแก้ไขเพิ่มเติม',
      DOC_REVISED_MSG: 'กรุณาแก้ไขเอกสารประกอบการประเมินการสอน',
      CERTIFICATE: 'ใบรับรอง',
      PART_3: 'ส่วนที่ 3 แบบประเมินผลการสอน',
      CURRENT_INFORMATION: 'ข้อมูลปัจจุบัน',
      FULL_NAME: 'ชื่อ-สกุล',
      SEMESTER: 'ภาคการศึกษา',
      EVALUATION_INFORMATION: 'รายละเอียดการประเมินผลการสอน',
      EVALUATION_CONFERENCE_DATE: 'วันที่ประชุมประเมินผลการสอน',
      APPROVE_REOPEN_DOC: 'อนุญาตแก้ไขคำขอประเมิน',
      APPROVE_REOPEN_REQ: 'อนุญาตแก้ไขคำขอ',
      EVALUATION_DATE: 'วันที่ประชุม',
      EVALUATION_DATE_INLINE: 'วันที่ประชุม:',
      TEACHING_EVALUATION_LEVEL: 'ผลการประเมินการสอนอยู่ในระดับ',
      EVALUATION_CERTIFIER: 'ผู้รับรองการประเมิน',
      POSITION: 'ตำแหน่ง',
      APPROVAL_DATE: 'วันที่อนุมัติ',
      DEPUTY_DEAN_APPROVAL: 'เสนอรองคณบดีเพื่อพิจารณาอนุมัติการประเมิน',
      DEPUTY_DEAN: 'รองคณบดี',
      DEAN_NAME: 'ชื่อคณบดี',
      DOCUMENT_STATUS_HISTORY: 'ประวัติสถานะเอกสาร',
      ASSESSMENT_REQUEST_TRACKING: 'ติดตามสถานะคำขอประเมิน',
      TRACKING_PAGE_WORDING: 'กรอกหมายเลขเอกสารหรือชื่อผู้ยื่นขอประเมิน',
      SEARCH_PLACEHOLDER: 'ค้นหาเลขที่, เรื่อง',
      SEARCH_USER_PLACEHOLDER: 'ค้นหาชื่อ, อีเมล',
      SEARCH_PLACEHOLDER_TRACKING: 'ค้นหาหมายเลข',
      TRACKING_PLACEHOLDER:
        'ค้นหาสถานะคำขอประเมิน คณะแพทยศาสตร์ มหาวิทยาลัยขอนแก่น',
      TRACKING_NOT_FOUND: 'ไม่พบเอกสารกรุณาตรวจสอบคำค้นหาหรือติดต่อเจ้าหน้าที่',
      EMAIL: 'อีเมล',
      GENERAL_INFORMATION: 'ข้อมูลทั่วไป',
      CONTACT_INFORMATION: 'ข้อมูลการดิดต่อ',
      PLACE: 'สถานที่',
      NEW_EVENT: 'สร้างกิจกรรมใหม่',
      START_TIME: 'เวลาเริ่มต้น',
      END_TIME: 'เวลาสิ้นสุด',
      EVENT_TITLE: 'ชื่อกิจกรรม',
      PREVIOUSLY_ASSESSED: 'หัวข้อที่เคยประเมินในระดับ {{position}}',
      IS_PREVIOUSLY_ASSESSED:
        'เป็นหัวข้อเดิมที่ใช้ประเมินการสอน {{value}} ใช่หรือไม่',
      TOPIC_NAME: 'ชื่อหัวข้อ',
      PLEASE_ATTACH_DOCUMENTATION: 'โปรดแนบเอกสารชี้แจง',
      CLARIFICATION_DOCUMENT: 'เอกสารชี้แจง',
      REQUESTER_INFORMATION: 'ข้อมูลผู้ยื่นขอประเมิน',
      ASSESSMENT_COMMITTEE: 'กรรมการประเมิน',
      RESERVE_ASSESSMENT_COMMITTEE: 'กรรมการประเมิน(สำรอง)',
      INTERNAL: 'ภายใน',
      EXTERNAL: 'ภายนอก',
      CHAIRMAN: 'ประธาน',
      CHAIRMAN_INLINE: 'ประธาน',
      ADD: 'เพิ่ม',
      ACKNOWLEDGE: 'การตอบรับ',
      AVAILABLE_DATE_TIME: 'วัน/เวลา ที่สะดวก',
      SELECT_AVAILABLE_MEETING_DATE_TIME:
        'กรุณาเลือก วัน/เวลา ที่สะดวกประชุมพิจารณา',
      SELECT_AVAILABLE_MEETING_DATE_TIME_DESC:
        '*กรุณาเลือกวันที่และเวลาเพื่อประชุมพิจารณาและสรุปผล',
      INVITATION_LETTER: 'หนังสือเชิญประชุม',
      SEGMENT: 'ส่วนงาน',
      DEAR_COMMITTEE: 'เรียนกรรมการ',
      CONTENT: 'เนื้อหา',
      REQUESTER_FULL_NAME: 'ชื่อ-สกุล ผู้ยื่นขอประเมิน',
      INVITATION_DATE_TIME: 'วัน/เวลา เชิญเข้าประชุม',
      TIME_FORMAT: 'ชั่วโมง:นาที',
      TIME: 'เวลา',
      LOCATION: 'สถานที่',
      ROOM: 'ห้อง',
      OFFICE: 'สำนักงาน',
      FLOOR: 'ชั้น',
      PROCESS_DATE: 'วันดำเนินการ',
      APPOINT_A_COMMITTEE: 'แต่งตั้งกรรมการ',
      TEACHING_EVALUATION_REPORT_FORM: 'แบบฟอร์มรายงานผลประเมินการสอน',
      EXPERT: 'ชำนาญ',
      SPECIAL_EXPERTISE: 'ชำนาญพิเศษ',
      SPECIALIZE: 'เชี่ยวชาญ',
      SUGGESTION_NOTES: 'บันทึกแจ้งข้อเสนอแนะ',
      SUGGESTION_NOTES_RESULT: 'บันทึกแจ้งผลการประเมิน',
      UPDATED_AT: 'วันที่อัปเดต',
      OPTION: 'ตัวเลือก',
      CHECK_THE_REVISED_DOCUMENT: 'ตรวจสอบเอกสารแก้ไข',
      NOTE: 'หมายเหตุ',
      REJECT_NOTE: 'หมายเหตุการปฏิเสธ',
      DISAPPROVE_NOTE: 'หมายเหตุการแก้ไข',
      MAIN_TEAM: 'ชุดหลัก',
      RESERVE_TEAM: 'ชุดสำรอง',
      ACCEPT: 'ตอบรับ',
      ALREADY_ACCEPT: 'ตอบรับแล้ว',
      REJECT: 'ปฏิเสธ',
      WAITING: 'รอตอบรับ',
      APPROVE: 'อนุมัติ',
      COMMENT: 'ความคิดเห็น',
      COMMENT_FOR_FILE: 'ความคิดเห็นสำหรับไฟล์',
      COMMITTEE_MEMBER: 'กรรมการ',
      DEAR_REQUESTER: 'เรียน ผู้ยื่นขอประเมิน',
      DETAIL: 'รายละเอียด',
      DETAIL_REVISED: 'รายละเอียดการแก้ไข',
      DETAIL_REJECT: 'รายละเอียดการแก้ไข',
      LAST_DETAIL_REJECT: 'รายละเอียดการแก้ไขก่อนหน้า',
      DOCUMENT_NAME: 'ชื่อเอกสารประกอบการสอน',
      EVALUATION_RESULT: 'ผลการประเมิน',
      WAITING_FOR_THE_COMMITTEE_RESPOND: 'รอกรรมการประเมินตอบรับ',
      INVITATION_TITLE: 'เรียนเชิญตอบรับเป็นอนุกรรมการประเมินผลการสอน',
      INVITATION_TEXT:
        'ขอเรียนเชิญท่านตอบรับเป็นคณะกรรมการประเมินผลการสอนเพื่อขอกำหนดตำแหน่งทางวิชาการ',
      COMMITTEE_INFORMATION: 'ข้อมูลกรรมการ',
      CREATE_APPOINT_LETTER:
        'สร้างหนังสือคำสั่งแต่งตั้งคณะกรรมการประเมินการสอน',
      APPOINT_LETTER: 'หนังสือคำสั่งแต่งตั้งคณะกรรมการประเมินการสอน',
      ORDER_OF_THE_FACULTY_OF_MEDICINE: 'คำสั่งคณะแพทย์ศาสตร์',
      APPOINT_DATE: 'วันที่แต่งตั้ง',
      ORDER_DATE: 'วันที่ออกคำสั่ง',
      DOC_DATE: 'วันที่สร้างเอกสาร',
      DOC_DATE_INLINE: 'วันที่สร้างเอกสาร:',
      EXPIRED_DATE: 'วันที่สิ้นสุดการแก้ไข',
      EXPIRED_DATE_INLINE: 'วันที่สิ้นสุดการแก้ไข:',
      ORDER_CONTENT: 'เนื้อหาคำสั่ง',
      ABOUT_1: 'ว่าด้วย',
      BY_VIRTUE_OF_THE_SECTION: 'อาศัยอำนาจตามความในมาตรา',
      LIST_OF_REQUESTER_AND_COMMITTEE: 'รายชื่อผู้เสนอขอและคณะอนุกรรมการ',
      ORDER_NO: 'ลำดับที่',
      POSITION_APPLICANT: 'ผู้ขอกำหนดตำแหน่ง',
      POSITIONING: 'ขอกำหนดตำแหน่ง',
      QUALIFIED: 'ผู้ทรงคุณวุฒิ',
      QUALIFIED_ADDRESS: 'ที่อยู่ผู้ทรงคุณวุฒิ',
      ORDER_MEMORANDUM:
        'บันทึกข้อความ หนังสือคำสั่งแต่งตั้งคณะกรรมการประเมินการสอน',
      POSITIONING_LIST: 'จำนวนผู้ยื่นประเมินการสอนกำหนดตำแหน่ง {{value}} ราย',
      MORNING: 'เช้า',
      AFTERNOON: 'บ่าย',
      LETTER_FILE:
        'ไฟล์หนังสือคำสั่งแต่งตั้งคณะกรรมการประเมินการสอนที่ได้รับการอนุมัติ',
      APPROVE_LETTER_FILE:
        'ไฟล์ใบรับรองการอนุมัติการขอกำหนดตำแหน่งทางวิชาการที่ได้รับการอนุมัติ',
      PLASE_SELECT_DATE: 'กรุณาเลือกวันที่',
      VIEW_FILE: 'ดูเอกสารประกอบ',
      COMMITTEE_INFO_NOT_FOUND:
        'ไม่พบข้อมูลการเชิญเป็นกรรมการประเมิน กรุณาติดต่อเจ้าหน้าที่เพื่อตรวจสอบข้อมูล',
      COMMITTEE_INFO_IS_REJECTED:
        'กรรมการประเมินได้ปฏิเสธเข้าร่วมประชุมการประเมินแล้ว',
      COMMITTEE_MAIN_IS_ACCEPTING:
        'กรรมการชุดหลักกำลังตรวจสอบช่วงเวลาในตอบรับการประชุม',
      WAITING_FOR_RESULT: 'รอผลการประเมินจากคณะกรรมการ',
      WAITING_FOR_REVISE: 'รอการแก้ไขเอกสารจากผู้ยื่นขอประเมิน',
      CHECKING_BY_CHAIRMAN: 'ประธานตรวจสอบเอกสารแก้ไข',
      CHAIRMAN_ONLY: 'ประเมินโดยประธานกคณะกรรมการเท่านั้น',
      WAITING_FOR_APPROVAL: 'รอการอนุมัติ',
      APPROVED: 'อนุมัติแล้ว',
      //
      INLINE_SEARCH: 'ค้นหา:',
      YES: 'ใช่',
      NO: 'ไม่ใช่',
      NO_EVENTS: 'ไม่มีกิจกรรม',
      NO_ITEMS: 'ไม่มีรายการ',
      NO_ITEMS_FOUND: 'ไม่มีรายการแสดง',
      NO_FILE_UPLOAD: 'ไม่มีไฟล์ที่อัปโหลด',
      NO_REVISED_FILE: 'ไม่มีไฟล์ที่แก้ไข',
      NO_COMMENTS: 'ไม่มีความคิดเห็น',
      SLOT_TIMES: 'ช่วงเวลา',
      PLEASE_COMMENT: 'กรุณากรอกคำแนะนำ',

      //Approver
      MEETING_INVITEE: 'ผู้เชิญประชุม',
      APPROVER: 'ผู้รับรอง',
      HEAD_OF_DEPARTMENT: 'หัวหน้าสาขา',
      HEAD_OF_DEPARTMENT_NAME: 'ชื่อหัวหน้าสาขา',
      APPROVER_NAME: 'ชื่อผู้รับรอง',
      APPROVER_DEPARTMENT: 'หัวหน้าภาค',
      APPROVER_CHIEF: 'หัวหน้าหน่วยการเจ้าหน้าที่',
      // APPROVER_DEAN: 'ประธานคณะอนุกรรมการ คณะแพทยศาสตร์',
      APPROVER_DEAN: 'ประธานอนุกรรมการประเมินผลการสอน  คณะแพทยศาสตร์',
      // APPROVER_SIRIKIT: 'รองคณบดีฝ่ายศูนย์หัวใจสิริกิติ์ ภาคตะวันออกเฉียงเหนือ และบริหารทรัพยากรบุคคล',
      APPROVER_SIRIKIT: 'รองคณบดีฝ่ายทรัพยากรบุคคล',

      USER: 'รายชื่อผู้ใช้',

      LOADING: 'กำลังโหลด',
      PROCESSING: 'กำลังประมวลผล',
      DOC_NO2: 'หมายเลขเอกสาร',

      // REPORTS
      DATE_PERIOD: 'วัน',
      MONTH_PERIOD: 'เดือน',
      YEAR_PERIOD: 'ปี',
      INLINE_DATE_RANGE: 'ช่วงวันที่:',
      INLINE_DATE: 'วันที่:',
      INLINE_MONTH: 'เดือน:',
      INLINE_YEAR: 'ปี:',
      TO: 'ถึง',
      START_DATE: 'วันที่เริ่มต้น',
      END_DATE: 'วันที่สิ้นสุด',
      REQUEST_DATE_FILTER: 'วันที่ยื่นเสนอขอ',
      APPROVE_DATE_FILTER: 'วันที่อนุมัติ',
      FIELD_OF_STUDY: 'สังกัดสาขาวิชา',
      REQUEST_STATUS: 'สถานะการยื่นขอ',
      OWNER_NAME: 'ชื่อผู้ยื่น',
      SELECT_COMMITTEE_PLACEHOLDER: 'กรุณาเลือกรายชื่อกรรมการ',
      COMMITTEE_NAME: 'ชื่อกรรมการ',
      INTERNAL_COMMITTEE: 'กรรมการภายใน',
      EXTERNAL_COMMITTEE: 'กรรมการภายนอก',
      ALL_COMMITTEE: 'รวมกรรมการทั้งสิ้น',
      COMMITTEE_TYPE: 'ประเภทกรรมการ',

      AMOUNT_DAY_APPROVE: 'จำนวนวันที่ขอเพิ่ม',

      ITEMS: 'รายการ',
    },
    COMPONENT: {
      ADD: 'เพิ่ม',
      NEW_REQUEST: 'สร้างคำขอประเมิน',
      CREATE: 'สร้าง',
      EDIT: 'แก้ไข',
      DELETE: 'ลบออก',
      VIEW_DATA: 'ดูข้อมูล',
      DOWNLOAD: 'ดาวน์โหลด',
      UPLOAD: 'อัปโหลด',
      BROWSE_FILE: 'เลือกไฟล์',
      PRINT: 'พิมพ์',
      PRINT_REQ: 'PDF เอกสารข้อมูลคำร้อง',
      CREATE_REQ_DOC: 'สร้างเอกสารแบบเสนอขอประเมินฯ',
      SAVE: 'บันทึก',
      SAVE_DRAFT: 'บันทึกแบบร่าง',
      SUBMIT_REQUEST: 'ยื่นขอประเมิน',
      VIEW_DETAIL: 'ดูรายละเอียด',
      SAVE_CHANGES: 'บันทึกการเปลี่ยนแปลง',
      NEW_EVENT: 'สร้างกิจกรรมใหม่',
      CONFIRM: 'ยืนยัน',
      ACCEPT: 'ตอบรับ',
      REJECT: 'ปฏิเสธ',
      SUBMIT_EDIT: 'ส่งแก้ไข',
      DISAPPROVE: 'ไม่อนุมัติ',
      APPROVE: 'อนุมัติ',
      APPROVED: 'ผ่านการตรวจสอบ',
      CONFIRM_REJECT: 'ยืนยืนปฏิเสธ',
      CONFIRM_DISAPPROVE: 'ยืนยันส่งแก้ไข',
      SUBMIT: 'ส่ง',
      VIEW_COMMENT: 'ดูความคิดเห็น',
      SUBMIT_REVISION_DOCUMENT: 'ส่งเอกสารแก้ไข',
      APPROPRIATE: 'เหมาะสม',
      MUST_REVISE: 'ต้องแก้ไข',
      SEND_EMAIL: 'ส่งอีเมล',
      ISSUE_A_CERTIFICATE: 'ออกใบรับรอง',
      CANCEL: 'ยกเลิก',
      CLOSE: 'ปิด',
      NEXT: 'ถัดไป',
      IMPORT_DATA: 'นำเข้าข้อมูล',
      IMPORT_PERSONNEL_DATA: 'นำเข้าข้อมูลบุคลากร',
      FILE_FORMAT: 'รูปแบบไฟล์',
    },
    VALIDATOR: {
      REQUIRED: 'กรุณากรอกข้อมูล',
      REQUIRED_DATE: 'กรุณากรอกข้อมูลตั้งแต่ 1 วันขึ้นไป',
      MUST_BE_AT_LEAST_X_CHARACTERS: 'จำนวนตัวอักษรอย่างน้อย {{value}} ตัว',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_LOWER_CASE:
        'ตัวพิมพ์เล็ก [a-z] อย่างน้อย {{value}} ตัว',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_UPPER_CASE:
        'ตัวพิมพ์ใหญ่ [A-Z] อย่างน้อย {{value}} ตัว',
      MUST_CONTAIN_AT_LEAST_X_NUMBER: 'ตัวเลข [0-9] อย่างน้อย {{value}} ตัว',
      INVALID_EMAIL: 'กรุณากรอกอีเมลที่ถูกต้อง',
      PASSWORD_MISMATCH: 'รหัสผ่านไม่ตรงกัน',
      PASSWORD_INVALID: "รูปแบบ 'รหัสผ่าน' ไม่ถูกต้อง",
      PLEASE_SELECT: 'กรุณาเลือก',
      PLEASE_SELECT_TIME_SLOTS: 'กรุณาเลือกช่วงเวลา',
      PLEASE_SELECT_FILE: 'กรุณาเลือกไฟล์',
      PLEASE_ADD_ALL_DOCUMENTS: 'กรุณาเพิ่มเอกสารให้ครบทุกหัวข้อ',

      DUPLICATE_DOC_NO: 'เลขที่เอกสารมีในระบบแล้วกรุณากรอกใหม่',
    },
    SWEETALERT: {
      CONFIRMATION_TITLE: 'การยืนยัน',
      CONFIRMATION_TEXT: 'คุณแน่ใจหรือว่าต้องการดำเนินการต่อ?',
      CONFIRMATION_DELETE_TEXT: 'คุณแน่ใจหรือว่าต้องการลบสิ้งนี้?',
      CONFIRMATION_SAVE_TITLE: 'ยืนยันบันทึก',
      CREATE_DATA: 'สร้างข้อมูล',
      CREATE_DATA_TEXT: 'คุณแน่ใจหรือว่าต้องการสร้างข้อมูลนี้?',
      UPDATE_DATA: 'อัปเดตข้อมูล',
      UPDATE_DATA_TEXT: 'คุณแน่ใจหรือว่าต้องการอัปเดตข้อมูลนี้?',
      SUCCESS_TITLE: 'สำเร็จ',
      SUCCESS_TEXT: 'สร้างข้อมูลใหม่เรียบร้อยแล้ว',
      SUCCESS_TEXT_UPDATE: 'ข้อมูลได้อัปเดตเรียบร้อยแล้วแล้ว',
      SUCCESS_TEXT_DELETE: 'ข้อมูลได้ถูกลบเรียบร้อยแล้ว',
      ERROR_TITLE: 'ไม่สำเร็จ',
      ERROR_TEXT: 'ดูเหมือนว่ามีบางอย่างผิดพลาด',
      WARNING_TITLE: 'คำเตือน',
      WARNING_TEXT: 'มีบางอย่างไม่ถูกต้อง อาจทำให้คุณไม่สามารถดำเนินการต่อได้',
      WARNING_IMAGE_SIZE_TITLE: 'รูปภาพมีขนาดใหญ่เกินไป',
      WARNING_IMAGE_SIZE_TEXT:
        'กรุณาตรวจสอบขนาดรูปภาพต้องไม่เกิน {{value}} {{unit}}.',

      ERROR_APPROVER_DUPLICATE_OWNER: 'ผู้ขอไม่สามารถเป็นผู้รับรองได้',
      ERROR_COMMITTEE_DUPLICATE: 'ท่านได้เลือกกรรมการท่านนี้แล้ว',
      ERROR_COMMITTEE_EXTERNAL: 'กรรมการภายนอกมีได้ไม่เกิน 1 คน',
      ERROR_COMMITTEE_INTERNAL: 'กรรมการภายในมีได้ไม่เกิน 2 คน',

      ERROR_NOTFOUND_DOC: 'ไม่พบหมายเลขเอกสาร',
      ERROR_NOTFOUND_DOC_BY_NAME: 'ไม่พบชื่อ-สกุลที่ค้นหา',
      ERROR_REQUIRED: 'กรุณากรอกข้อมูลให้ครบ',
      //
      APPROVE_CONFIRMATION: 'ยืนยันอนุมัติ',
      SUCCESS_APPROVE_TEXT: 'อนุมัติข้อมูลคำร้องเรียบร้อยแล้ว',
      REJECT_CONFIRMATION: 'ยืนยันไม่อนุมัติ',
      SUCCESS_REJECT_TEXT: 'หมายเหตุการแก้ไขได้ถูกส่งไปยังผู้ยื่นคำร้องแล้ว',
      CONFIRMATION_SAVE_DRAFT_TITLE: 'บันทึกแบบร่าง',
      CONFIRMATION_SUBMIT_REQUEST_TITLE: 'ยืนยันยื่นขอประเมิน',
      ACCEPT_INVITATION_CONFIRMATION: 'ตอบรับคำเชิญ',
      SEND_INVITATION_LETTER: 'ส่งหนังสือเชิญประชุม',
      SEND_EVALUATION_RESULT: 'ส่งผลการประเมิน',
      EDIT_EVALUATION_RESULT: 'แก้ไขผลการประเมิน',
      SEND_REQUEST_RESULT: 'ส่งบันทึกแจ้งข้อเสนอแนะ',
      SEND_TO_CHAIRMAN_TO_AUDIT: 'ส่งให้ประธานคณะกรรมการตรวจสอบ',
      ISSUE_A_CERTIFICATE: 'สร้างใบรับรอง',
      APPROVE_A_CERTIFICATE: 'อนุมัติใบรับรอง',
      SAVE_DOCUMENT_RESULT: 'บันทึกคำแนะนำเอกสาร',
    },
    MISC: {
      HELLO: 'สวัสดี',
      ASSOC_PROF: 'ผู้ช่วยศาสตราจารย์',
      SORRY: 'ขออภัย',
      MOBILE_NOT_SUPPORTED: 'ระบบยังไม่รองรับอุปกรณ์มือถือ',
      ONLY_USE_VIA_IPAD_DESKTOP: 'เข้าใช้งานได้เฉพาะ iPad, Tablet, Desktop',
    },
    TABLE: {
      NO_DATA_DISPLAY: 'ไม่มีรายการแสดง',
      NO_DATA: 'ไม่มีรายการ',
      TOTAL: 'รวม',
      SUMMARY_TOTAL: 'รวมทั้งหมด',
      TOTAL_RESULT: 'ทั้งหมด',
      TOTAL_LIST: 'รายการ',
      CUSTOMER_PATIENT: 'ลูกค้า / ผู้ป่วย',
      AGE: 'อายุ',
      AGE_YEAR: 'อายุ (ปี)',
      ID: 'เลขที่ประจำตัวบัตรประชาชน',
      PHONE: 'เบอร์ติดต่อ',
      EMERGENCY: 'ฉุกเฉิน',
      LINE: 'LINE',
      LAST_UPDATED: 'แก้ไขล่าสุด',
      LAST_LOGIN: 'เข้าสู่ระบบล่าสุด',
      OPTION: 'ตัวเลือก',
      BY: 'by',
      CONNECTED: 'เชื่อมต่อแล้ว',
      NOT_CONNECTED: 'ไม่ได้เชื่อมต่อ',
      DOC_NO: 'เลขที่',
      DATE: 'วันที่',
      OPD_LIST: 'รายการตรวจ',
      DIAG_LIST: 'การรักษา',
      RESPONSIBLE_DOCTOR: 'แพทย์ผู้รับผิดชอบ',
      SUB_TOTAL: 'ราคารวม',
      NET_PRICE: 'ยอดรวม',
      PAID_PRICE: 'ยอดที่ต้องชำระ',
      STATUS: 'สถานะ',
      PAID: 'ชำระแล้ว',
      VOLUME_NO_DOC_NO: 'เล่มที่/เลขที่',
      REFERENCE: 'อ้างอิงจาก',
      ISSUED_BY: 'ออกให้โดย',
      ITEMS: 'รายการ',
      PRICE_PER_UNIT: 'ราคา/หน่วย',
      AMOUNT: 'จำนวน',
      DISCOUNT: 'ส่วนลด',
      BAHT: 'บาท',
      MEDICINE_USAGE: 'การใช้ยา',
      VAT: 'ภาษีมูลค่าเพิ่ม ({{value}})',
      SERVICE_DATE: 'วันที่รับบริการ',
      COMPLETED_DATE: 'วันที่รับบริการเสร็จสิ้น',
      EXAMINATION_ROOM: 'ห้องตรวจรักษา',

      // REPORT
      RECEIPT_NO: 'เลขที่ใบเสร็จ',
      APPOINTMENT: 'นัดหมาย',

      // SETTING
      CODE: 'รหัส',
      PRICE: 'ราคา',
      UNIT: 'หน่วย',
      NUMBER_OF_MEDICINE: 'จำนวนรายการยา',
      ACCOUNT_NAME: 'ชื่อบัญชี',
      ACCOUNT_NO: 'เลขที่บัญชี',
      USED_FOR: 'ใช้สำหรับ',
      NAME: 'ชื่อ',
      NAME_CODE: 'ชื่อ / รหัส',
      LICENSE_NO: 'เลขที่ใบอนุญาต',
      EMAIL: 'อีเมล',
      ROLE: 'บทบาท',
      DESCRIPTION: 'คำอธิบาย',
      GROUP: 'กลุ่ม',
      CATEGORY: 'หมวดหมู่',
      TYPE: 'ประเภท',
      MEDICINE_ITEM_COUNT: 'จำนวนรายการยา',
      MEDICINE_CATEGORY: 'หมวดหมู่ยา',
      MEDICINE_TYPE: 'ประเภทยา',
      MEDICINE_NAME: 'ชื่อยา',
      DOCTOR: 'แพทย์',
      FEE: 'ค่าแพทย์',
      UNIT_NAME: 'ชื่อหน่วยนับ',
      PRIMARY_UNIT: 'หน่วยหลัก',
      MULTIPILIER: 'อัตรา/หน่วย',
      MEDICINE_EQUIPMENT_PRICE_PER_TIME: 'ค่ายา/อุปกรณ์ (/ครั้ง)',
      TIMES: 'จำนวนครั้ง',
      SELL_PRICE: 'ราคาขาย',
      USES_AMOUNTPER_TIME: 'จำนวนที่ใช้/ครั้ง',
      TYPE_OF_SERVICE: 'ลักษณะการให้บริการ',
      SHOW: 'แสดง',
      ENTRIES: 'รายการ',
      ALLERGIC_DRUG_NAME: 'ชื่อยาที่แพ้',
      SYMPTOMS_WHEN_ALLERGIC_TO_DRUGS: 'อาการเมื่อแพ้ยา',
      ALLERGIC_DRUG_GROUP: 'กลุ่มยาที่แพ้',

      // REPORT
      OWNER_NAME: 'ชื่อผู้ยื่น',
      REQUEST_POSITION: 'ตำแหน่งทางวิชาการที่ขอ',
      FIELD_OF_STUDY: 'สังกัดสาขาวิชา',
      REQUEST_DATE: 'วันที่ผู้ยื่นขอประเมิน',
      APPROVE_DATE: 'วันที่อนุมัติ',
      REQUEST_STATUS: 'สถานะการยื่นขอ',
      PROCESS_TIME_SECTION_1:
        'ระยะเวลาดำเนินการ (วัน) ช่วงที่ 1 <br> (ยื่นเสนอขอ - เลือกกรรมการประเมิน)',
      PROCESS_TIME_SECTION_2:
        'ระยะเวลาดำเนินการ (วัน) ช่วงที่ 2 <br> (เลือกกรรมการประเมิน - ประชุมและพิจารณา)',
      PROCESS_TIME_SECTION_3:
        'ระยะเวลาดำเนินการ (วัน) ช่วงที่ 3 <br> (ประชุมและพิจารณา - อนุมัติ)',
      COMMITTEE_NAME: 'ชื่อกรรมการ',
      COMMITTEE_POSITION: 'ตำแหน่งกรรมการประเมิน',
      EVAL_POSITION: 'ตำแหน่งทางวิชาการที่ประเมิน',
      NUMBER_OF_REQUEST: 'จำนวนครั้งที่ประเมินต่อ',
      NUMBER_OF_ASSIS_PROF: 'จำนวนผู้ยื่นขอผู้ช่วยศาสตราจารย์',
      NUMBER_OF_SP_ASSIS_PROF: 'จำนวนผู้ยื่นขอผู้ช่วยศาสตราจารย์พิเศษ',
      NUMBER_OF_ASSOC_PROF: 'จำนวนผู้ยื่นขอรองศาสตราจารย์',
      NUMBER_OF_SP_ASSOC_PROF: 'จำนวนผู้ยื่นขอรองศาสตราจารย์พิเศษ',
      NUMBER_OF_ASSIS_PROF_TABLE: 'จำนวนผู้ยื่นขอ <br> ผู้ช่วยศาสตราจารย์',
      NUMBER_OF_SP_ASSIS_PROF_TABLE:
        'จำนวนผู้ยื่นขอ <br> ผู้ช่วยศาสตราจารย์พิเศษ',
      NUMBER_OF_ASSOC_PROF_TABLE: 'จำนวนผู้ยื่นขอ <br> รองศาสตราจารย์',
      NUMBER_OF_SP_ASSOC_PROF_TABLE: 'จำนวนผู้ยื่นขอ <br> รองศาสตราจารย์พิเศษ',
      Q_TIMES: 'ครั้ง',
      Q_PEOPLE: 'คน',
      COMMITTEE_TYPE: 'ประเภทกรรมการ',
      NUMBER_OF_REQUESTER: 'จำนวนผู้ยื่นขอทั้งหมด',

      EVALUATION_DATE: 'วันที่ประชุม',
      FACULTY: 'คณะ',
    },
  },
};
