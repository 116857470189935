import { Component, HostListener, OnInit } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ScreenResizeService } from 'utils/screen-resize.service';

@Component({
  selector: 'screen-size-detector',
  templateUrl: './screen-size-detector.component.html',
  styleUrls: ['./screen-size-detector.component.scss']
})
export class ScreenSizeDetectorComponent implements OnInit {

  subscription: Subscription;

  screenSize: string;
  showBlinding: boolean;

  public coreConfig: any;

  constructor(
    private resizeSvc: ScreenResizeService,
    private _coreConfigService: CoreConfigService,
  ) {
    this.coreConfig = this._coreConfigService.configObject;
  }

  @HostListener("window:resize", ['$event'])
  onResize($event) {
    this.detectScreenSize($event);
  }

  ngOnInit(): void {
    this.subscription = this.resizeSvc.onResize$.pipe(distinctUntilChanged()).subscribe((res) => {
      this.screenSize = res;
      if (['xs', 'sm'].includes(this.screenSize)) {
        document.getElementsByTagName('body')[0].classList.add('blinding');
        this.showBlinding = true;
      } else {
        document.getElementsByTagName('body')[0].classList.remove('blinding');
        this.showBlinding = false;
      }
    });
  }

  ngAfterViewInit() {
    const payload = {
      target: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight
      }
    }
    this.detectScreenSize(payload);
  }

  detectScreenSize($event: any) {
    const payload = {
      width: $event.target.innerWidth,
      height: $event.target.innerHeight
    }
    this.resizeSvc.onResize(payload);
  }
}
