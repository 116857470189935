export const locale = {
  lang: 'en',
  data: {
    LOGIN: {
      WELCOME_TEXT: 'Welcome to',
      WELCOME_DESC: 'Log in to your account and start using the system',
      USERNAME: 'Username',
      EMAIL: 'Email',
      PASSWORD: 'Password',
      SIGN_IN: 'Sign In',
      SIGN_OUT: 'Sign Out',
      INVALID_LOGIN: 'The username or password you entered is not valid. Please double-check and try again.',
      INVALID_LOGIN_SSO: 'User not found or KKU Mail is invalid. Please check and try again.',
      SOCIAL_LOGIN: 'Or',
      SSO_SUCCESS: 'Login SSO Success',
      LOGIN_KKU: 'Login by KKU mail here',
    },
    MENU_TITLE: {
      ASSESSMENT_REQUEST: 'Assessment Requests',
      REQUESTS: 'Requests',
      SCHEDULE_CALENDAR: 'Schedule Calendar',
      TRACKING: 'Tracking',
      IMPORT_DATA: 'Import Data',
      USER_MANAGEMENT: 'User Management',
      PROFILE: 'Profile',
      REVIEWER_LIST: 'Reviewer List',
      APPOINTMENT_DOC: 'Appointment doc',
      APPOINTMENT_LETTER: 'Appointment letter',
      REPORTS: {
        SECTION: 'Reports',
        REQUESTER_REPORT: 'Requester Report',
        COMMITTEE_INDIVIDUAL_REPORT: 'Committee Report (Individual)',
        COMMITTEES_REPORT: 'Committee Summary Report',
      },
    },
    APP: {
      ACCOUNT_PROFILE: 'Account Profile',
      DRAFT: 'Draft',
      WAITING_DOC: 'Waiting Document',
      IN_PROGRESS: 'In Progress',
      HISTORY: 'History',
      ATTACHMENTS: 'Attachments',
      DOC_NO: 'Doc No',
      DATE: 'Date',
      DOCUMENT_NO: 'Document No',
      REQUEST_INFORMATION: 'Request Information',
      CONCLUSION: 'Conclusion',
      REVISE_FILE: 'Revise File',
      TIMELINE: 'Timeline',
      ISSUE_NO: 'Issue No',
      SUBJECT: 'Subject',
      DEAR: 'Dear',
      NAME_TITLE: 'Name Title',
      NAME: 'Name',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      TYPE: 'Type',
      CURRENT_POSITION: 'Current Position',
      ACADEMIC_POSITION: 'Academic Position',
      SKILL: 'Skill',
      MAJOR: 'Major',
      MAJOR_NAME: 'Major Name',
      DEPARTMENT_MAJOR: 'Department Major',
      DEPARTMENT: 'Department',
      FACULTY: 'Faculty',
      PHONE: 'Phone',
      DOCUMENT_CREATER: 'Document Creater',
      REQUESTER: 'Requester',
      REQUESTER_INLINE: 'Requester:',
      DATE_FORMAT: 'DD/MM/YYYY(B.E. E.g. {{year}})',
      REQUEST_EVALUATION: 'Request Evaluation',
      FIRST_SEMESTER: 'First Semester',
      SECOND_SEMESTER: 'Second Semester',
      SEMESTER_YEAR: 'Year',
      YEAR: 'Year',
      MONTH: 'Month',
      DAY: 'Day',
      SUBJECT_CODE: 'Subject Code',
      SUBJECT_NAME: 'Subject Name',
      SUBJECT_NAME_PLACEHOLDER: 'TH Name (EN Name)',
      DEGREEE: 'Degree',
      REQUEST_POSITION: 'Request Position',
      TOPIC: 'Topic',
      TAUGHT_TOPIC: 'Taught Topic',
      PLEASE_SPECIFY: 'Please Specify',
      EVALUATION_LECTURE_SUBJECT: 'Evaluation Lecture Subject',
      EVALUATION_PRACTICAL_SUBJECT: 'Evaluation Practical Subject',
      FILE_SECTION_TXT_1:
        'Topics previously assessed at the Assistant Professor (Application for Assoc. Prof. using Asst. Prof. level)',
      FILE_SECTION_TXT_2: 'Teaching Assessment Documents',
      FILE_SECTION_TXT_VALUE: 'Teaching Assessment Documents {{value}} files',
      FILE_NAME: 'File Name',
      FILE_PLACEHOLDER: 'Browse or drop file here',
      FILE_PLACEHOLDER_ADD_ON: 'Support PDF file',
      FILE_PLACEHOLDER_2: 'Drag and drop file here. Or',
      COPY_OF_APPLICATION_FROM_HEAD:
        'A copy of the approved application by department',
      COPY_OF_APPLICATION_FROM_HEAD_VIEW:
        'A copy of the approved application by department',
      COPY_OF_APPLICATION_FROM_HEAD_WARNING:
        'Please print, sign and attach this document. before submitting the request form',
      UPLOADED_FILE: 'Uploaded File',
      REVISION_FILE: 'Revision',
      REMARK: 'Remark',
      BY: 'by',
      TEL: 'Tel.',
      PASS: 'Pass',
      FAIL: 'Fail',
      HAS_ADDITIONAL_EDIT: 'Has Additional Edit',
      NO_ADDITIONAL_EDIT: 'No Additional Edit',
      DOC_REVISED_MSG: 'Please revised the teaching assessment documentation',
      CERTIFICATE: 'Certificate',
      PART_3: 'Part 3 Teaching Evaluation Form',
      CURRENT_INFORMATION: 'Current Information',
      FULL_NAME: 'Full Name',
      SEMESTER: 'Semester',
      EVALUATION_INFORMATION: 'Evaluation Information',
      EVALUATION_CONFERENCE_DATE: 'Evaluation Conference Date',
      APPROVE_REOPEN_DOC: 'Approve Reopen Doc',
      APPROVE_REOPEN_REQ: 'Approve Reopen Request',
      EVALUATION_DATE: 'Evaluation Date',
      EVALUATION_DATE_INLINE: 'Evaluation Date:',
      TEACHING_EVALUATION_LEVEL: 'Evaluation Level',
      EVALUATION_CERTIFIER: 'Evaluation Certifier',
      POSITION: 'Position',
      APPROVAL_DATE: 'Approval Date',
      DEPUTY_DEAN_APPROVAL:
        'Propose the Deputy Dean to consider and approve the evaluation',
      DEPUTY_DEAN: 'Deputy Dean',
      DEAN_NAME: 'Dean Name',
      DOCUMENT_STATUS_HISTORY: 'Document Status History',
      ASSESSMENT_REQUEST_TRACKING: 'Assessment Request Tracking',
      TRACKING_PAGE_WORDING:
        'Enter the document number or the name of the applicant for the assessment.',
      SEARCH_PLACEHOLDER: 'Search...',
      SEARCH_USER_PLACEHOLDER: 'Search...',
      SEARCH_PLACEHOLDER_TRACKING: 'Search...',
      TRACKING_PLACEHOLDER:
        'Tracking status of the assessment request. Faculty of Medicine, Khon Kaen University',
      TRACKING_NOT_FOUND:
        'Documents not found, please check your keywords or contact the staff.',
      EMAIL: 'Email',
      GENERAL_INFORMATION: 'General Information',
      CONTACT_INFORMATION: 'Contact Information',
      PLACE: 'Place',
      NEW_EVENT: 'New Event',
      START_TIME: 'Start Time',
      END_TIME: 'End Time',
      EVENT_TITLE: 'Event Title',
      PREVIOUSLY_ASSESSED: 'Topics previously assessed at the {{position}}',
      IS_PREVIOUSLY_ASSESSED:
        'Is it the same topic used to assess teaching {{value}}?',
      TOPIC_NAME: 'Topic Name',
      PLEASE_ATTACH_DOCUMENTATION: 'Please attach documentation',
      CLARIFICATION_DOCUMENT: 'Clarification Document',
      REQUESTER_INFORMATION: "Requestor's information",
      ASSESSMENT_COMMITTEE: 'Assessment Committee',
      RESERVE_ASSESSMENT_COMMITTEE: 'Assessment Committee(reserve)',
      INTERNAL: 'Internal',
      EXTERNAL: 'External',
      CHAIRMAN: 'Chairman',
      CHAIRMAN_INLINE: 'Chairman:',
      ADD: 'Add',
      ACKNOWLEDGE: 'Acknowledge',
      AVAILABLE_DATE_TIME: 'Convenient date/time',
      SELECT_AVAILABLE_MEETING_DATE_TIME:
        'Please select a date/time convenient for the meeting.',
      SELECT_AVAILABLE_MEETING_DATE_TIME_DESC:
        '*Please select a date and time convenient for the meeting.',
      INVITATION_LETTER: 'Invitation Letter',
      SEGMENT: 'Segment',
      DEAR_COMMITTEE: 'Dear Committee',
      CONTENT: 'Content',
      REQUESTER_FULL_NAME: "Requestor's full name",
      INVITATION_DATE_TIME: 'Date/Time For Invitation',
      TIME_FORMAT: 'Time HH:MM',
      TIME: 'HH:MM',
      LOCATION: 'Location',
      ROOM: 'Room',
      OFFICE: 'Office',
      FLOOR: 'Floor',
      PROCESS_DATE: 'Process Date',
      APPOINT_A_COMMITTEE: 'Appoint a Committee',
      TEACHING_EVALUATION_REPORT_FORM: 'Teaching evaluation report form',
      EXPERT: 'Expert',
      SPECIAL_EXPERTISE: 'Special Expertise',
      SPECIALIZE: 'Specialize',
      SUGGESTION_NOTES: 'Suggestion Notes',
      SUGGESTION_NOTES_RESULT: 'Suggestion Notes Result',
      UPDATED_AT: 'Updated at',
      OPTION: 'Option',
      CHECK_THE_REVISED_DOCUMENT: 'Check The Revised Document',
      NOTE: 'Note',
      REJECT_NOTE: 'Reject Note',
      DISAPPROVE_NOTE: 'Edit Note',
      MAIN_TEAM: 'Main',
      RESERVE_TEAM: 'Reserve',
      ACCEPT: 'Accept',
      ALREADY_ACCEPT: 'Already Accept',
      REJECT: 'Reject',
      WAITING: 'Waiting',
      APPROVE: 'Approve',
      COMMENT: 'Comment',
      COMMENT_FOR_FILE: 'Comment file',
      COMMITTEE_MEMBER: 'Committee',
      DEAR_REQUESTER: 'Dear Requestor',
      DETAIL: 'Detail',
      DETAIL_REVISED: 'Detail Revised',
      DETAIL_REJECT: 'Detail',
      LAST_DETAIL_REJECT: 'Last Detail',
      DOCUMENT_NAME: 'Document Name',
      EVALUATION_RESULT: 'Evaluation Result',
      WAITING_FOR_THE_COMMITTEE_RESPOND: 'Waiting for Respond',
      INVITATION_TITLE:
        'Invitation to accept as a teaching evaluation committee',
      INVITATION_TEXT:
        'We would like to invite you to accept as a teaching evaluation committee to request academic position determination.',
      COMMITTEE_INFORMATION: 'Committee Information',
      CREATE_APPOINT_LETTER:
        'Create an order letter to appoint a teaching evaluation committee',
      APPOINT_LETTER: 'Order letter to appoint a teaching evaluation committee',
      ORDER_OF_THE_FACULTY_OF_MEDICINE: 'Order of the Faculty of Medicine',
      APPOINT_DATE: 'Appoint Date',
      ORDER_DATE: 'Order Date',
      DOC_DATE: 'DOC Date',
      DOC_DATE_INLINE: 'DOC Date:',
      EXPIRED_DATE: 'Expired Date',
      EXPIRED_DATE_INLINE: 'Expired Date:',
      ORDER_CONTENT: 'Order Content',
      ABOUT_1: 'About',
      BY_VIRTUE_OF_THE_SECTION: 'By virtue of the section',
      LIST_OF_REQUESTER_AND_COMMITTEE: 'List of Requesters and Committees',
      ORDER_NO: 'Order No.',
      POSITION_APPLICANT: 'Position Applicant',
      POSITIONING: 'Positioning',
      QUALIFIED: 'Qualified',
      QUALIFIED_ADDRESS: 'Qualified Address',
      ORDER_MEMORANDUM:
        'Memorandum of the letter of order to appoint the teaching evaluation committee',
      POSITIONING_LIST:
        'Number of applicants for teaching positioning assessment: {{value}}',
      MORNING: 'Morning',
      AFTERNOON: 'Afternoon',
      LETTER_FILE:
        'The letter of order to appoint the teaching evaluation committee files',
      APPROVE_LETTER_FILE:
        'The letter of order to approve the teaching evaluation committee files',
      PLASE_SELECT_DATE: 'Please Select Date',
      VIEW_FILE: 'View File',
      COMMITTEE_INFO_NOT_FOUND:
        'Can not find your information about being an appraisal committee. Please contact the staff.',
      COMMITTEE_INFO_IS_REJECTED:
        'The committee has declined to attend the assessment meeting.',
      COMMITTEE_MAIN_IS_ACCEPTING:
        'The main team committee is accepting to attend the assessment meeting.',
      WAITING_FOR_RESULT: 'Waiting for evaluation results from the committee.',
      WAITING_FOR_REVISE: 'Waiting for document revision from requestor.',
      CHECKING_BY_CHAIRMAN: 'Checking by Chairman',
      CHAIRMAN_ONLY: 'Assessed by the Chairman only.',
      WAITING_FOR_APPROVAL: 'Waiting for Approval',
      APPROVED: 'Approved',
      //
      INLINE_SEARCH: 'Search:',
      YES: 'Yes',
      NO: 'No',
      NO_EVENTS: 'No events',
      NO_ITEMS: 'No Items',
      NO_ITEMS_FOUND: 'No Items Found',
      NO_FILE_UPLOAD: 'No File Uploaded',
      NO_REVISED_FILE: 'No Revised File',
      NO_COMMENTS: 'No Comments',

      SLOT_TIMES: 'Slot times',
      PLEASE_COMMENT: 'Please comment',

      //Approver
      MEETING_INVITEE: 'Meeting Invitee',
      APPROVER: 'Approver',
      HEAD_OF_DEPARTMENT: 'Head of the Department',
      HEAD_OF_DEPARTMENT_NAME: 'Head of the Department name',
      APPROVER_NAME: 'Approver Name',
      APPROVER_DEPARTMENT: 'Department Leader',
      APPROVER_CHIEF: 'Chief Officer',
      APPROVER_DEAN: 'Dean Approver',
      APPROVER_SIRIKIT: 'Siriki Approver',

      USER: 'User List',

      LOADING: 'Loading',
      PROCESSING: 'Processing',
      DOC_NO2: 'Doc No.',

      // REPORTS
      DATE_PERIOD: 'Date',
      MONTH_PERIOD: 'Month',
      YEAR_PERIOD: 'Year',
      INLINE_DATE_PERIOD: 'Date Period:',
      INLINE_DATE: 'Date:',
      INLINE_MONTH: 'Month:',
      INLINE_YEAR: 'Year:',
      TO: 'To',
      START_DATE: 'Start Date',
      END_DATE: 'End Date',
      REQUEST_DATE_FILTER: 'Request Date',
      APPROVE_DATE_FILTER: 'Approve Date',
      FIELD_OF_STUDY: 'Field of Study',
      REQUEST_STATUS: 'Status',
      OWNER_NAME: 'Requester',
      SELECT_COMMITTEE_PLACEHOLDER: 'Please select committee.',
      COMMITTEE_NAME: 'Committee',
      INTERNAL_COMMITTEE: 'Internal Committee',
      EXTERNAL_COMMITTEE: 'External Committee',
      ALL_COMMITTEE: 'All Committee',
      COMMITTEE_TYPE: 'Committee Type',

      AMOUNT_DAY_APPROVE: 'Amount Day Approve',

      ITEMS: 'Items',
    },
    COMPONENT: {
      ADD: 'Add',
      NEW_REQUEST: 'New Request',
      CREATE: 'Create',
      EDIT: 'Edit',
      DELETE: 'Delete',
      VIEW_DATA: 'View Data',
      DOWNLOAD: 'Download',
      UPLOAD: 'Upload',
      BROWSE_FILE: 'Browse File',
      PRINT: 'Print',
      PRINT_REQ: 'PDF Request Document',
      CREATE_REQ_DOC: 'Create Request Document',
      SAVE: 'Save',
      SAVE_DRAFT: 'Save As Draft',
      SUBMIT_REQUEST: 'Submit Request',
      VIEW_DETAIL: 'View Detail',
      SAVE_CHANGES: 'Save Changes',
      NEW_EVENT: 'New Event',
      CONFIRM: 'Confirm',
      ACCEPT: 'Accept',
      REJECT: 'Reject',
      SUBMIT_EDIT: 'Submit Edit',
      DISAPPROVE: 'Disapprove',
      APPROVE: 'Approve',
      APPROVED: 'Approved',
      CONFIRM_REJECT: 'Confirm Reject',
      CONFIRM_DISAPPROVE: 'Confirm Disapprove',
      SUBMIT: 'Submit',
      VIEW_COMMENT: 'View Comment',
      SUBMIT_REVISION_DOCUMENT: 'Submit Revision Document',
      APPROPRIATE: 'Appropriate',
      MUST_REVISE: 'Must Revise',
      SEND_EMAIL: 'Send Email',
      ISSUE_A_CERTIFICATE: 'Issue a Certificate',
      CANCEL: 'Cancel',
      CLOSE: 'Close',
      NEXT: 'Next',
      IMPORT_DATA: 'Import Data',
      IMPORT_PERSONNEL_DATA: 'Import Personnel Data',
      FILE_FORMAT: 'File Format',
    },
    VALIDATOR: {
      REQUIRED: 'Field cannot be blank',
      REQUIRED_DATE: 'Must be at least 1 Day',
      MUST_BE_AT_LEAST_X_CHARACTERS: 'Must be at least {{value}} characters',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_LOWER_CASE:
        'Must contain at least {{value}} letter in lower case',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_UPPER_CASE:
        'Must contain at least {{value}} letter in upper case',
      MUST_CONTAIN_AT_LEAST_X_NUMBER: 'Must contain at least {{value}} number',
      INVALID_EMAIL: 'Please enter a valid email address',
      PASSWORD_MISMATCH: "Those passwords didn't match",
      PASSWORD_INVALID: "Invalid 'Password' pattern.",
      PLEASE_SELECT: 'Please select',
      PLEASE_SELECT_TIME_SLOTS: 'Please select time slots',
      PLEASE_SELECT_FILE: 'Please select file',
      PLEASE_ADD_ALL_DOCUMENTS: 'Please Add All Documents',

      DUPLICATE_DOC_NO:
        'The document number already exists in the system, please enter it again.',
    },
    SWEETALERT: {
      CONFIRMATION_TITLE: 'Confirmation',
      CONFIRMATION_TEXT: 'Are you sure you want to proceed this?',
      CONFIRMATION_DELETE_TEXT: 'Are you sure you want to delete this?',
      CONFIRMATION_SAVE_TITLE: 'Confirmation',
      CREATE_DATA: 'Create Data',
      CREATE_DATA_TEXT: 'Are you sure to create this data?',
      UPDATE_DATA: 'Update Data',
      UPDATE_DATA_TEXT: 'Are you sure to update this data?',
      SUCCESS_TITLE: 'Success',
      SUCCESS_TEXT: 'New information has been created.',
      SUCCESS_TEXT_UPDATE: 'We have successfully updated the information.',
      SUCCESS_TEXT_DELETE: 'We have successfully deleted this information.',
      ERROR_TITLE: 'Oops',
      ERROR_TEXT: 'Sorry, something went wrong.',
      WARNING_TITLE: 'Warning',
      WARNING_TEXT:
        'Something is not right, It may prevent you from continuing.',
      WARNING_IMAGE_SIZE_TITLE: 'Image is too large',
      WARNING_IMAGE_SIZE_TEXT:
        'Please make sure the image size is not larger than {{value}} {{unit}}',

      ERROR_APPROVER_DUPLICATE_OWNER: 'Owner can not be approver',
      ERROR_COMMITTEE_DUPLICATE: 'You have already elected this director.',
      ERROR_COMMITTEE_EXTERNAL: 'External committee must contain at least 1',
      ERROR_COMMITTEE_INTERNAL: 'Internal committee must contain at least 2',

      ERROR_NOTFOUND_DOC: 'Document number not found',
      ERROR_NOTFOUND_DOC_BY_NAME: 'Document not found by name',
      ERROR_REQUIRED: 'Field cannot be blank',

      //
      APPROVE_CONFIRMATION: 'Approve Confirmation',
      SUCCESS_APPROVE_TEXT: 'The assessment request has been approved.',
      REJECT_CONFIRMATION: 'Reject Confirmation',
      SUCCESS_REJECT_TEXT: 'A revised note has been sent to the applicant.',
      CONFIRMATION_SAVE_DRAFT_TITLE: 'Save Draft',
      CONFIRMATION_SUBMIT_REQUEST_TITLE: 'Submit Request',
      ACCEPT_INVITATION_CONFIRMATION: 'Accept Invitation',
      SEND_INVITATION_LETTER: 'Send Meeting Invitation',
      SEND_EVALUATION_RESULT: 'Send Evaluation Result',
      EDIT_EVALUATION_RESULT: 'Edit Evaluation Result',
      SEND_REQUEST_RESULT: 'Send Conclusion Result',
      SEND_TO_CHAIRMAN_TO_AUDIT: 'Sent to Chairman',
      ISSUE_A_CERTIFICATE: 'Issue a Certificate',
      APPROVE_A_CERTIFICATE: 'Approve a Certificate',
      SAVE_DOCUMENT_RESULT: 'Save Document Note.',
    },
    MISC: {
      HELLO: 'Hello',
      ASSOC_PROF: 'Associate Professor',
      SORRY: 'Sorry',
      MOBILE_NOT_SUPPORTED: 'Mobile devices in not supported.',
      ONLY_USE_VIA_IPAD_DESKTOP: 'It can only be accessed on iPad, Tablet, and Desktop.',
    },
    TABLE: {
      NO_DATA_DISPLAY: 'No data to display',
      NO_DATA: 'No data',
      TOTAL: 'Total',
      SUMMARY_TOTAL: 'Summary Total',
      TOTAL_RESULT: 'Total',
      TOTAL_LIST: '',
      CUSTOMER_PATIENT: 'Customer / Patient',
      AGE: 'Age',
      AGE_YEAR: 'Age (Year)',
      ID: 'ID',
      PHONE: 'Phone',
      EMERGENCY: 'Emergency',
      LINE: 'LINE',
      LAST_UPDATED: 'Last Updated',
      LAST_LOGIN: 'Last Login',
      OPTION: 'Option',
      BY: 'by',
      CONNECTED: 'Connected',
      NOT_CONNECTED: 'Not Connected',
      DOC_NO: 'Doc No.',
      DATE: 'Date',
      OPD_LIST: 'OPD List',
      DIAG_LIST: 'Diag. List',
      RESPONSIBLE_DOCTOR: 'Responsible Doctor',
      SUB_TOTAL: 'Total Price',
      NET_PRICE: 'Total Price',
      PAID_PRICE: 'Total',
      STATUS: 'Status',
      PAID: 'Paid',
      VOLUME_NO_DOC_NO: 'Vol No./Doc No.',
      REFERENCE: 'Reference',
      ISSUED_BY: 'Issues by',
      ITEMS: 'Items',
      PRICE_PER_UNIT: 'Price/Unit',
      AMOUNT: 'Amount',
      DISCOUNT: 'Discount',
      BAHT: 'Baht',
      MEDICINE_USAGE: 'Medicine Usage',
      VAT: 'Vat. ({{value}})',
      SERVICE_DATE: 'Service Date',
      COMPLETED_DATE: 'Completed Date',
      EXAMINATION_ROOM: 'Examination Room',

      // REPORT
      RECEIPT_NO: 'Receipt No',
      APPOINTMENT: 'Appointment',

      // SETTING
      CODE: 'Code',
      PRICE: 'Price',
      UNIT: 'Unit',
      NUMBER_OF_MEDICINE: 'No. of Med.',
      ACCOUNT_NAME: 'Account Name',
      ACCOUNT_NO: 'Account No.',
      USED_FOR: 'Used For',
      NAME: 'Name',
      NAME_CODE: 'Name / Code',
      LICENSE_NO: 'License No.',
      EMAIL: 'Email',
      ROLE: 'Role',
      DESCRIPTION: 'Description',
      GROUP: 'Group',
      CATEGORY: 'Category',
      TYPE: 'Type',
      MEDICINE_ITEM_COUNT: 'Medecine Items Count',
      MEDICINE_CATEGORY: 'Medicine Category',
      MEDICINE_TYPE: 'Medicine Type',
      MEDICINE_NAME: 'Medocine Name',
      DOCTOR: 'Doctor',
      FEE: 'Fee',
      UNIT_NAME: 'Unit Name',
      PRIMARY_UNIT: 'Primary Unit',
      MULTIPILIER: 'Amt./Unt.',
      MEDICINE_EQUIPMENT_PRICE_PER_TIME: 'Med./Equip. (/Time)',
      TIMES: 'Times',
      SELL_PRICE: 'Sell Price',
      USES_AMOUNTPER_TIME: 'Uses/Times',
      TYPE_OF_SERVICE: 'Type of Service',
      SHOW: 'Show',
      ENTRIES: 'Entries',
      ALLERGIC_DRUG_NAME: 'Allergic Drug Name',
      SYMPTOMS_WHEN_ALLERGIC_TO_DRUGS: 'Symptoms When Allergic',
      ALLERGIC_DRUG_GROUP: 'Allergic Drug Group',

      // REPORT
      OWNER_NAME: 'Name',
      REQUEST_POSITION: 'Request Position',
      FIELD_OF_STUDY: 'Field of Study',
      REQUEST_DATE: 'Request Date',
      APPROVE_DATE: 'Approve Date',
      REQUEST_STATUS: 'Status',
      PROCESS_TIME_SECTION_1: 'Process Time (Day) Section 1',
      PROCESS_TIME_SECTION_2: 'Process Time (Day) Section 2',
      PROCESS_TIME_SECTION_3: 'Process Time (Day) Section 3',
      COMMITTEE_NAME: 'Committee',
      COMMITTEE_POSITION: 'Committee Position',
      EVAL_POSITION: 'Evaluation Position',
      NUMBER_OF_REQUEST: 'Number of Request',
      NUMBER_OF_ASSIS_PROF: 'Number of request for Assistant Professor',
      NUMBER_OF_SP_ASSIS_PROF:
        'Number of request for Special Assistant Professor',
      NUMBER_OF_ASSOC_PROF: 'Number of request for Associate Professor',
      NUMBER_OF_SP_ASSOC_PROF:
        'Number of request for Special Associate Professor',
      NUMBER_OF_ASSIS_PROF_TABLE:
        'Number of request <br> for Assistant Professor',
      NUMBER_OF_SP_ASSIS_PROF_TABLE:
        'Number of request <br> for Special Assistant Professor',
      NUMBER_OF_ASSOC_PROF_TABLE:
        'Number of request for <br> Associate Professor',
      NUMBER_OF_SP_ASSOC_PROF_TABLE:
        'Number of request <br> for Special Associate Professor',
      Q_TIMES: 'Times',
      Q_PEOPLE: 'People',
      COMMITTEE_TYPE: 'Committee Type',
      NUMBER_OF_REQUESTER: 'Number of Requester',

      EVALUATION_DATE: 'Evaluation Date',
      FACULTY: 'Faculty',
    },
  },
};
